'use client';

import { Product } from '@/services/types/productTypes';
import { get } from 'lodash';
import { useParams } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

interface ProductContextProps {
	product: Product | null;
	setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
	selectedAttributes: {
		[key: string]: string;
	};
	setSelectedAttributes: React.Dispatch<
		React.SetStateAction<{
			[key: string]: string;
		}>
	>;
	selectedVariant: any | null;
	setSelectedVariant: React.Dispatch<React.SetStateAction<any | null>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	productImages: Array<any>;
	setProductImages: React.Dispatch<React.SetStateAction<Array<any>>>;
	isEnglish: boolean;
	setIsEnglish: React.Dispatch<React.SetStateAction<boolean>>;
	globalData: any;
	setGlobalData: React.Dispatch<React.SetStateAction<any>>;
	facts: any;
	setFacts: React.Dispatch<React.SetStateAction<any>>;
	isFactsLoading: boolean;
	showHamburger: any;
	setShowHamburger: any;
	setIsFactsLoading: React.Dispatch<React.SetStateAction<boolean>>;

	setShowGenericModel: any;
	showGenericModel: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined);
interface ProductProviderProps {
	children: React.ReactNode;
}

export const ProductProvider = ({ children }: any) => {
	const params = useParams();
	const [product, setProduct] = useState<Product | null>(null);
	const [selectedAttributes, setSelectedAttributes] = useState<{
		[key: string]: string;
	}>({});
	const [selectedVariant, setSelectedVariant] = useState<any | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [productImages, setProductImages] = useState<Array<any>>([]);
	const [isEnglish, setIsEnglish] = useState<boolean>(true);
	const [globalData, setGlobalData] = useState<any>({});
	const [showHamburger, setShowHamburger] = useState<any>(true);
	const [showGenericModel, setShowGenericModel] = useState<any>(false);
	useEffect(() => {
		setProductImages([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		setProduct((prevProduct) => ({
			...prevProduct,
			p_name: isEnglish
				? get(product, 'translation.en.p_name', product?.p_name)
				: get(product, 'translation.bn.p_name', product?.p_name)
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEnglish]);

	useEffect(() => {
		setProduct(null);
		setSelectedAttributes({});
		setSelectedVariant(null);
		setLoading(true);
		setProductImages([]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEnglish]);
	
	const [facts, setFacts] = useState<any>({});
	const [isFactsLoading, setIsFactsLoading] = useState<boolean>(true);

	return (
		<ProductContext.Provider
			value={{
				product,
				setProduct,
				selectedAttributes,
				setSelectedAttributes,
				selectedVariant,
				setSelectedVariant,
				loading,
				setLoading,
				productImages,
				setProductImages,
				setIsEnglish,
				isEnglish,
				globalData,
				setGlobalData,
				facts,
				setFacts,
				isFactsLoading,
				setIsFactsLoading,
				showHamburger,
				setShowHamburger,
				showGenericModel,
				setShowGenericModel
			}}>
			{children}
		</ProductContext.Provider>
	);
};

const useProduct = () => {
	const context = useContext(ProductContext);
	if (context === undefined) {
		throw new Error('useProduct must be used within a ProductProvider');
	}
	return context;
};

export default useProduct;
