import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { API_ACCOUNT } from '@/services/apis/apiConstrant';
import { updateCartAddress as updateLabTestCartAddress } from '@labServices/apis/lab-test-apis/cart';
import Form from 'ar-form';
import { get, map, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import { FaBriefcase } from 'react-icons/fa';
import { MdHomeFilled, MdOutlineApartment } from 'react-icons/md';
import Button from '../../atoms/Button';
import { FormInput, FormRadioButton, FormSelect, FormSwitch, FormTreeSelect } from '../../atoms/FormInputs';
import Modal from '../../atoms/Modal';
import Radio from '../../atoms/Radio';
import SpinnerWrapper from '../../atoms/SpinnerWrapper';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	form: any;
	refetch: any;
	onfinish?: any;
	setDrawerOpen(boolean): void;
	parent?: string;
}

const AddAddressModal = ({ isOpen, onClose, form, refetch, onfinish, setDrawerOpen, parent }: IProps) => {
	const [loading, setLoading] = useState(false);
	const alert = useAlert();
	const { getUserProfile } = useAuth();
	const [allAreas, setAllAreas]: any = useState([]);
	const [subAreas, setSubAreas]: any = useState([]);
	useEffect(() => {
		const fetcher = async () => {
			setLoading(true);
			const { data } = await AroggaAPI.get(API_ACCOUNT.ADDRESS.GET_ALL_LOCATION);
			setAllAreas(data);
			setLoading(false);
		};
		isOpen && fetcher();
		form.setFieldsValue({
			ul_type: form.getFieldValue('ul_type') || 'Home'
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleOnClose = () => {
		onClose();
		form.resetFields();
		setSubAreas([]);
	};

	const onFinish = async (values) => {
		setLoading(true);
		const url = values.id ? API_ACCOUNT.ADDRESS.UPDATE(values.id) : API_ACCOUNT.ADDRESS.GET;
		const { status, message, data } = await AroggaAPI.post(url, {
			...values,
			ul_default: values.ul_default ? 1 : 0
		});

		if (status === 'success') {
			// LAB TEST CART ADDRESS UPDATE
			await updateLabTestCartAddress(data?.ul_id, data?.l_id);

			getUserProfile();
			setLoading(false);
			refetch();
			handleOnClose();
			// onfinish && onfinish();
			const message = values.id ? 'Address Updated' : 'Address Added';
		} else {
			alert.error({
				message: message || 'Something went wrong'
			});
		}
		setLoading(false);
	};

	const newTreeData = map(allAreas, (districts, division) => ({
		label: division,
		value: toLower(division),
		count: map(districts, (district) => Object.keys(district).length).reduce((a, b) => a + b, 0),
		children: map(districts, (areas, district) => ({
			label: district,
			value: toLower(district),
			count: Object.keys(areas).length,
			children: map(areas, (details, area) => ({
				label: area,
				value: get(details, 'l_id', null)
			}))
		}))
	}));
	const s_area = form.getFieldValue('ul_sa_id');

	const getSubAreas = async (value) => {
		const { data } = await AroggaAPI.get('/general/v1/subArea', {
			_l_id: value
		});
		setSubAreas([
			{
				sa_id: null,
				sa_title: 'Select Sub Area'
			},
			...(data || [])
		]);
	};

	useEffect(() => {
		if (s_area) {
			getSubAreas(form.getFieldValue('l_id'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [s_area]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				handleOnClose();
			}}
			footer={null}
			width={580}
			title={form.getFieldValue('id') ? 'Update Shipping Address' : 'Add Shipping Address'}
			maskClosable={false}
			bodyStyle={{
				borderRadius: '15px',
				backgroundColor: '##F9FAFB',
				padding: 30
			}}>
			<SpinnerWrapper loading={loading}>
				<Form form={form} onFinish={onFinish}>
					<Form.Field name={'id'} />
					<FormInput
						label='Full Name'
						name='ul_name'
						placeholder='Enter full name'
						rules={[
							{
								required: true,
								message: 'Please enter full name'
							}
						]}
					/>
					<FormInput
						label='Phone Number'
						name='ul_mobile'
						placeholder='Enter phone number'
						rules={[
							{
								required: true,
								message: 'Please enter phone number'
							},
							{
								pattern: new RegExp('^(?:\\+88|01)?(?:\\d{11}|\\d{13})$'),

								message: 'Invalid phone number'
							}
						]}
					/>
					<FormTreeSelect
						label='Select Delivery Area'
						name='l_id'
						placeholder='Select delivery area'
						showCount={true}
						rules={[
							{
								required: true,
								message: 'Please enter address'
							}
						]}
						options={newTreeData}
						onChange={(value) => {
							getSubAreas(value);
						}}
					/>

					{subAreas?.length > 1 && (
						<FormSelect
							label='Sub Area (Optional)'
							name='ul_sa_id'
							placeholder='Select sub area'
							options={subAreas.map((item) => ({
								value: item.sa_id,
								label: item.sa_title
							}))}
							rules={[
								{
									required: false,
									message: 'Please enter address'
								}
							]}
						/>
					)}
					<div className='d-flex gap-20'>
						<FormInput
							label='Address'
							name='ul_address'
							placeholder='Type House, Flat No, Road'
							rules={[
								{
									required: true,
									message: 'Please enter address'
								}
							]}
						/>
					</div>
					<FormRadioButton
						label='Address Type'
						name='ul_type'
						defaultValue='Home'
						placeholder='Enter address'
						rules={[
							{
								required: true,
								message: 'Please select address type'
							}
						]}>
						<Radio.Button icon={<MdHomeFilled size={20} />} value='Home' size='large'>
							Home
						</Radio.Button>
						<Radio.Button icon={<FaBriefcase size={20} />} value='Office' size='large'>
							Office
						</Radio.Button>
						<Radio.Button value='Hometown' icon={<MdOutlineApartment size={20} />} size='large'>
							Hometown
						</Radio.Button>
					</FormRadioButton>

					<FormSwitch label='Make default address' name='ul_default' placeholder='Enter address' />

					<div className='d-flex '>
						<Button
							onClick={() => {
								form.submit();
								parent !== 'drawer' && setDrawerOpen(false);
							}}
							style={{
								width: '100%',
								height: '48px'
							}}
							className=' m-auto'
							label={form.getFieldValue('id') ? 'Update' : 'Save'}
							type='primary'
						/>
					</div>
				</Form>
			</SpinnerWrapper>
		</Modal>
	);
};

export default AddAddressModal;
